import React, { MouseEvent } from 'react';
import theme from 'stories/theme';

import { Svg } from './styles';

interface PerformanceIconProps {
  onClick?: undefined | ((e: MouseEvent) => void);
  height?: string;
  width?: string;
  color?: string;
  as?: React.ElementType;
}

export const PerformanceIcon = ({
  onClick,
  height = '24',
  width = '24',
  color = theme.colors.icons.blue,
  as,
}: PerformanceIconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    as={as}
    color={color}
    onClick={onClick}
  >
    <path
      d="M3 18V6V10.3V10V18ZM3 20C2.45 20 1.97917 19.8042 1.5875 19.4125C1.19583 19.0208 1 18.55 1 18V6C1 5.45 1.19583 4.97917 1.5875 4.5875C1.97917 4.19583 2.45 4 3 4H8.175C8.44167 4 8.69583 4.05 8.9375 4.15C9.17917 4.25 9.39167 4.39167 9.575 4.575L11 6H19C19.55 6 20.0208 6.19583 20.4125 6.5875C20.8042 6.97917 21 7.45 21 8V10.975C20.7 10.7583 20.3833 10.5708 20.05 10.4125C19.7167 10.2542 19.3667 10.1167 19 10V8H10.175L8.175 6H3V18H9.875C9.925 18.35 10.0042 18.6917 10.1125 19.025C10.2208 19.3583 10.35 19.6833 10.5 20H3Z"
      fill={color}
    />
    <path
      d="M12.5442 21C12.3953 21 12.2674 20.9487 12.1604 20.846C12.0535 20.7433 12 20.6161 12 20.4643V14.75C12 14.5982 12.0527 14.471 12.1581 14.3683C12.2635 14.2656 12.3942 14.2143 12.55 14.2143H14.3892C14.5381 14.2143 14.666 14.2656 14.7729 14.3683C14.8799 14.471 14.9333 14.5982 14.9333 14.75V20.4643C14.9333 20.6161 14.8806 20.7433 14.7752 20.846C14.6698 20.9487 14.5392 21 14.3833 21H12.5442ZM16.5775 21C16.4286 21 16.3007 20.9487 16.1937 20.846C16.0868 20.7433 16.0333 20.6161 16.0333 20.4643V11.5357C16.0333 11.3839 16.086 11.2567 16.1915 11.154C16.2969 11.0513 16.4275 11 16.5833 11H18.4225C18.5714 11 18.6993 11.0513 18.8063 11.154C18.9132 11.2567 18.9667 11.3839 18.9667 11.5357V20.4643C18.9667 20.6161 18.914 20.7433 18.8085 20.846C18.7031 20.9487 18.5725 21 18.4167 21H16.5775ZM20.6108 21C20.4619 21 20.334 20.9487 20.2271 20.846C20.1201 20.7433 20.0667 20.6161 20.0667 20.4643V15.8214C20.0667 15.6696 20.1194 15.5424 20.2248 15.4397C20.3302 15.3371 20.4608 15.2857 20.6167 15.2857H22.4558C22.6047 15.2857 22.7326 15.3371 22.8396 15.4397C22.9465 15.5424 23 15.6696 23 15.8214V20.4643C23 20.6161 22.9473 20.7433 22.8419 20.846C22.7365 20.9487 22.6058 21 22.45 21H20.6108Z"
      fill={color}
    />
  </Svg>
);
