import { ElementType, MouseEvent } from 'react';
import theme from 'stories/theme';

import { Svg } from './styles';

interface DocumentIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  as?: ElementType;
  variant?: number;
}

export const DocumentIcon = ({
  onClick,
  height = '24',
  width = '24',
  color = theme.colors.icons.blue,
  as,
  variant = 1,
}: DocumentIconProps) => {
  switch (variant) {
    case 1: {
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          color={color}
          as={as}
          onClick={onClick}
        >
          <path
            d="M5 23C4.45 23 3.97917 22.8042 3.5875 22.4125C3.19583 22.0208 3 21.55 3 21V8C3 7.71667 3.09583 7.47917 3.2875 7.2875C3.47917 7.09583 3.71667 7 4 7C4.28333 7 4.52083 7.09583 4.7125 7.2875C4.90417 7.47917 5 7.71667 5 8V21H15C15.2833 21 15.5208 21.0958 15.7125 21.2875C15.9042 21.4792 16 21.7167 16 22C16 22.2833 15.9042 22.5208 15.7125 22.7125C15.5208 22.9042 15.2833 23 15 23H5Z"
            fill={color}
          />
          <path
            d="M10.6875 16.2H16.3125C16.5781 16.2 16.8008 16.109 16.9805 15.9269C17.1602 15.7448 17.25 15.5192 17.25 15.25C17.25 14.9808 17.1602 14.7552 16.9805 14.5731C16.8008 14.391 16.5781 14.3 16.3125 14.3H10.6875C10.4219 14.3 10.1992 14.391 10.0195 14.5731C9.83984 14.7552 9.75 14.9808 9.75 15.25C9.75 15.5192 9.83984 15.7448 10.0195 15.9269C10.1992 16.109 10.4219 16.2 10.6875 16.2ZM10.6875 12.4H16.3125C16.5781 12.4 16.8008 12.309 16.9805 12.1269C17.1602 11.9448 17.25 11.7192 17.25 11.45C17.25 11.1808 17.1602 10.9552 16.9805 10.7731C16.8008 10.591 16.5781 10.5 16.3125 10.5H10.6875C10.4219 10.5 10.1992 10.591 10.0195 10.7731C9.83984 10.9552 9.75 11.1808 9.75 11.45C9.75 11.7192 9.83984 11.9448 10.0195 12.1269C10.1992 12.309 10.4219 12.4 10.6875 12.4ZM7.875 20C7.35938 20 6.91797 19.814 6.55078 19.4419C6.18359 19.0698 6 18.6225 6 18.1V2.9C6 2.3775 6.18359 1.93021 6.55078 1.55813C6.91797 1.18604 7.35938 1 7.875 1H14.6016C14.8516 1 15.0898 1.0475 15.3164 1.1425C15.543 1.2375 15.7422 1.37208 15.9141 1.54625L20.4609 6.15375C20.6328 6.32792 20.7656 6.52979 20.8594 6.75938C20.9531 6.98896 21 7.23042 21 7.48375V18.1C21 18.6225 20.8164 19.0698 20.4492 19.4419C20.082 19.814 19.6406 20 19.125 20H7.875ZM14.4375 6.7V2.9H7.875V18.1H19.125V7.65H15.375C15.1094 7.65 14.8867 7.55896 14.707 7.37687C14.5273 7.19479 14.4375 6.96917 14.4375 6.7Z"
            fill={color}
          />
        </Svg>
      );
    }
    case 2: {
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_21306_17236)">
            <path
              d="M18.6654 3.16666H7.9987C6.53203 3.16666 5.34536 4.36666 5.34536 5.83332L5.33203 27.1667C5.33203 28.6333 6.5187 29.8333 7.98536 29.8333H23.9987C25.4654 29.8333 26.6654 28.6333 26.6654 27.1667V11.1667L18.6654 3.16666ZM7.9987 27.1667V5.83332H17.332V12.5H23.9987V27.1667H7.9987Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_21306_17236">
              <rect
                width="32"
                height="32"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </Svg>
      );
    }
    case 3: {
      return (
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18H15C15.2833 18 15.5208 17.9042 15.7125 17.7125C15.9042 17.5208 16 17.2833 16 17C16 16.7167 15.9042 16.4792 15.7125 16.2875C15.5208 16.0958 15.2833 16 15 16H9C8.71667 16 8.47917 16.0958 8.2875 16.2875C8.09583 16.4792 8 16.7167 8 17C8 17.2833 8.09583 17.5208 8.2875 17.7125C8.47917 17.9042 8.71667 18 9 18ZM9 14H15C15.2833 14 15.5208 13.9042 15.7125 13.7125C15.9042 13.5208 16 13.2833 16 13C16 12.7167 15.9042 12.4792 15.7125 12.2875C15.5208 12.0958 15.2833 12 15 12H9C8.71667 12 8.47917 12.0958 8.2875 12.2875C8.09583 12.4792 8 12.7167 8 13C8 13.2833 8.09583 13.5208 8.2875 13.7125C8.47917 13.9042 8.71667 14 9 14ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H13.175C13.4417 2 13.6958 2.05 13.9375 2.15C14.1792 2.25 14.3917 2.39167 14.575 2.575L19.425 7.425C19.6083 7.60833 19.75 7.82083 19.85 8.0625C19.95 8.30417 20 8.55833 20 8.825V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 8V4H6V20H18V9H14C13.7167 9 13.4792 8.90417 13.2875 8.7125C13.0958 8.52083 13 8.28333 13 8Z"
            fill={color}
          />
        </Svg>
      );
    }
    default: {
      return null;
    }
  }
};
