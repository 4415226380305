import { useAuth } from 'modules/auth/providers/AuthProvider';
import Link from 'next/link';
import { ElementType } from 'react';
import theme from 'stories/theme';

import { SalesRepSubnavLinkProps } from './style';
interface SalesRepButtonNavigationProps {
  LinkTitle: string;
  hrefLink: string;
  Icon: ElementType;
  active: boolean;
}

const SalesRepButtonNavigation = ({
  Icon,
  LinkTitle,
  active,
  hrefLink,
}: SalesRepButtonNavigationProps) => {
  const { logout } = useAuth();

  const callLogout = () => {
    logout('/');
  };
  if (LinkTitle === 'Log Out') {
    return (
      <SalesRepSubnavLinkProps isActive={active} onClick={callLogout}>
        <Icon color={active ? theme.colors.grey[0] : theme.colors.grey[400]} />
        <p>{LinkTitle}</p>
      </SalesRepSubnavLinkProps>
    );
  }
  return (
    <Link passHref href={hrefLink} legacyBehavior>
      <SalesRepSubnavLinkProps isActive={active} data-testid="navButton">
        <Icon color={active ? theme.colors.grey[0] : theme.colors.grey[400]} />
        <p>{LinkTitle}</p>
      </SalesRepSubnavLinkProps>
    </Link>
  );
};

export default SalesRepButtonNavigation;
