import { MouseEvent } from 'react';

import { Svg } from './styles';

interface TaxCenterIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  isActive?: boolean;
  fillColor?: string;
}

export const TaxCenterIcon = ({
  onClick,
  height = '36',
  width = '36',
  color = '#AAAAAA',
  isActive,
  fillColor = 'url(#paint0_linear_19755_48526)',
}: TaxCenterIconProps) =>
  isActive ? (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7.5 25.125V17.625C7.5 17.2 7.64375 16.8438 7.93125 16.5563C8.21875 16.2688 8.575 16.125 9 16.125C9.425 16.125 9.78125 16.2688 10.0688 16.5563C10.3563 16.8438 10.5 17.2 10.5 17.625V25.125C10.5 25.55 10.3563 25.9062 10.0688 26.1938C9.78125 26.4813 9.425 26.625 9 26.625C8.575 26.625 8.21875 26.4813 7.93125 26.1938C7.64375 25.9062 7.5 25.55 7.5 25.125ZM16.5 25.125V17.625C16.5 17.2 16.6438 16.8438 16.9313 16.5563C17.2188 16.2688 17.575 16.125 18 16.125C18.425 16.125 18.7813 16.2688 19.0688 16.5563C19.3563 16.8438 19.5 17.2 19.5 17.625V25.125C19.5 25.55 19.3563 25.9062 19.0688 26.1938C18.7813 26.4813 18.425 26.625 18 26.625C17.575 26.625 17.2188 26.4813 16.9313 26.1938C16.6438 25.9062 16.5 25.55 16.5 25.125ZM4.5 32.625C4.075 32.625 3.71875 32.4812 3.43125 32.1937C3.14375 31.9062 3 31.55 3 31.125C3 30.7 3.14375 30.3438 3.43125 30.0563C3.71875 29.7688 4.075 29.625 4.5 29.625H31.5C31.925 29.625 32.2812 29.7688 32.5687 30.0563C32.8562 30.3438 33 30.7 33 31.125C33 31.55 32.8562 31.9062 32.5687 32.1937C32.2812 32.4812 31.925 32.625 31.5 32.625H4.5ZM25.5 25.125V17.625C25.5 17.2 25.6438 16.8438 25.9313 16.5563C26.2188 16.2688 26.575 16.125 27 16.125C27.425 16.125 27.7812 16.2688 28.0687 16.5563C28.3562 16.8438 28.5 17.2 28.5 17.625V25.125C28.5 25.55 28.3562 25.9062 28.0687 26.1938C27.7812 26.4813 27.425 26.625 27 26.625C26.575 26.625 26.2188 26.4813 25.9313 26.1938C25.6438 25.9062 25.5 25.55 25.5 25.125ZM31.5 13.125H4.35C3.975 13.125 3.65625 12.9938 3.39375 12.7313C3.13125 12.4688 3 12.15 3 11.775V10.95C3 10.675 3.06875 10.4375 3.20625 10.2375C3.34375 10.0375 3.525 9.875 3.75 9.75L16.65 3.3C17.075 3.1 17.525 3 18 3C18.475 3 18.925 3.1 19.35 3.3L32.175 9.7125C32.45 9.8375 32.6563 10.025 32.7938 10.275C32.9313 10.525 33 10.7875 33 11.0625V11.625C33 12.05 32.8562 12.4062 32.5687 12.6938C32.2812 12.9812 31.925 13.125 31.5 13.125ZM9.675 10.125H26.325L18 6L9.675 10.125Z"
        fill={fillColor}
      />
      <defs>
        <linearGradient
          id="paint0_linear_19755_48526"
          x1="3"
          y1="3"
          x2="32.6227"
          y2="32.9976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF6767" />
          <stop offset="1" stop-color="#AD0015" />
        </linearGradient>
      </defs>
    </Svg>
  ) : (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7.5 25.125V17.625C7.5 17.2 7.64375 16.8438 7.93125 16.5563C8.21875 16.2688 8.575 16.125 9 16.125C9.425 16.125 9.78125 16.2688 10.0688 16.5563C10.3563 16.8438 10.5 17.2 10.5 17.625V25.125C10.5 25.55 10.3563 25.9062 10.0688 26.1938C9.78125 26.4813 9.425 26.625 9 26.625C8.575 26.625 8.21875 26.4813 7.93125 26.1938C7.64375 25.9062 7.5 25.55 7.5 25.125ZM16.5 25.125V17.625C16.5 17.2 16.6438 16.8438 16.9313 16.5563C17.2188 16.2688 17.575 16.125 18 16.125C18.425 16.125 18.7813 16.2688 19.0688 16.5563C19.3563 16.8438 19.5 17.2 19.5 17.625V25.125C19.5 25.55 19.3563 25.9062 19.0688 26.1938C18.7813 26.4813 18.425 26.625 18 26.625C17.575 26.625 17.2188 26.4813 16.9313 26.1938C16.6438 25.9062 16.5 25.55 16.5 25.125ZM4.5 32.625C4.075 32.625 3.71875 32.4812 3.43125 32.1937C3.14375 31.9062 3 31.55 3 31.125C3 30.7 3.14375 30.3438 3.43125 30.0563C3.71875 29.7688 4.075 29.625 4.5 29.625H31.5C31.925 29.625 32.2812 29.7688 32.5687 30.0563C32.8562 30.3438 33 30.7 33 31.125C33 31.55 32.8562 31.9062 32.5687 32.1937C32.2812 32.4812 31.925 32.625 31.5 32.625H4.5ZM25.5 25.125V17.625C25.5 17.2 25.6438 16.8438 25.9313 16.5563C26.2188 16.2688 26.575 16.125 27 16.125C27.425 16.125 27.7812 16.2688 28.0687 16.5563C28.3562 16.8438 28.5 17.2 28.5 17.625V25.125C28.5 25.55 28.3562 25.9062 28.0687 26.1938C27.7812 26.4813 27.425 26.625 27 26.625C26.575 26.625 26.2188 26.4813 25.9313 26.1938C25.6438 25.9062 25.5 25.55 25.5 25.125ZM31.5 13.125H4.35C3.975 13.125 3.65625 12.9938 3.39375 12.7313C3.13125 12.4688 3 12.15 3 11.775V10.95C3 10.675 3.06875 10.4375 3.20625 10.2375C3.34375 10.0375 3.525 9.875 3.75 9.75L16.65 3.3C17.075 3.1 17.525 3 18 3C18.475 3 18.925 3.1 19.35 3.3L32.175 9.7125C32.45 9.8375 32.6563 10.025 32.7938 10.275C32.9313 10.525 33 10.7875 33 11.0625V11.625C33 12.05 32.8562 12.4062 32.5687 12.6938C32.2812 12.9812 31.925 13.125 31.5 13.125ZM9.675 10.125H26.325L18 6L9.675 10.125Z"
        fill={color}
      />
    </Svg>
  );
