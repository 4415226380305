import { ElementType, MouseEvent } from 'react';
import theme from 'stories/theme';

import { Svg } from './styles';

interface TaxCenterProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  as?: ElementType;
}

export const TaxCenter = ({
  onClick,
  height = '24',
  width = '24',
  color = theme.colors.icons.blue,
  as,
}: TaxCenterProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    as={as}
    onClick={onClick}
    data-testid="taxCenterIcon"
  >
    <path
      data-testid="icon-path"
      d="M5 16.75V11.75C5 11.4667 5.09583 11.2292 5.2875 11.0375C5.47917 10.8458 5.71667 10.75 6 10.75C6.28333 10.75 6.52083 10.8458 6.7125 11.0375C6.90417 11.2292 7 11.4667 7 11.75V16.75C7 17.0333 6.90417 17.2708 6.7125 17.4625C6.52083 17.6542 6.28333 17.75 6 17.75C5.71667 17.75 5.47917 17.6542 5.2875 17.4625C5.09583 17.2708 5 17.0333 5 16.75ZM11 16.75V11.75C11 11.4667 11.0958 11.2292 11.2875 11.0375C11.4792 10.8458 11.7167 10.75 12 10.75C12.2833 10.75 12.5208 10.8458 12.7125 11.0375C12.9042 11.2292 13 11.4667 13 11.75V16.75C13 17.0333 12.9042 17.2708 12.7125 17.4625C12.5208 17.6542 12.2833 17.75 12 17.75C11.7167 17.75 11.4792 17.6542 11.2875 17.4625C11.0958 17.2708 11 17.0333 11 16.75ZM3 21.75C2.71667 21.75 2.47917 21.6542 2.2875 21.4625C2.09583 21.2708 2 21.0333 2 20.75C2 20.4667 2.09583 20.2292 2.2875 20.0375C2.47917 19.8458 2.71667 19.75 3 19.75H21C21.2833 19.75 21.5208 19.8458 21.7125 20.0375C21.9042 20.2292 22 20.4667 22 20.75C22 21.0333 21.9042 21.2708 21.7125 21.4625C21.5208 21.6542 21.2833 21.75 21 21.75H3ZM17 16.75V11.75C17 11.4667 17.0958 11.2292 17.2875 11.0375C17.4792 10.8458 17.7167 10.75 18 10.75C18.2833 10.75 18.5208 10.8458 18.7125 11.0375C18.9042 11.2292 19 11.4667 19 11.75V16.75C19 17.0333 18.9042 17.2708 18.7125 17.4625C18.5208 17.6542 18.2833 17.75 18 17.75C17.7167 17.75 17.4792 17.6542 17.2875 17.4625C17.0958 17.2708 17 17.0333 17 16.75ZM21 8.75H2.9C2.65 8.75 2.4375 8.6625 2.2625 8.4875C2.0875 8.3125 2 8.1 2 7.85V7.3C2 7.11667 2.04583 6.95833 2.1375 6.825C2.22917 6.69167 2.35 6.58333 2.5 6.5L11.1 2.2C11.3833 2.06667 11.6833 2 12 2C12.3167 2 12.6167 2.06667 12.9 2.2L21.45 6.475C21.6333 6.55833 21.7708 6.68333 21.8625 6.85C21.9542 7.01667 22 7.19167 22 7.375V7.75C22 8.03333 21.9042 8.27083 21.7125 8.4625C21.5208 8.65417 21.2833 8.75 21 8.75ZM6.45 6.75H17.55L12 4L6.45 6.75Z"
      fill={color}
    />
  </Svg>
);
