import { MouseEvent } from 'react';

import { Svg } from './styles';

interface DocIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  isActive?: boolean;
  fillColor?: string;
}

export const DocIcon = ({
  onClick,
  height = '36',
  width = '36',
  color = '#AAAAAA',
  isActive,
  fillColor = 'url(#paint0_linear_19755_48516)',
}: DocIconProps) =>
  isActive ? (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7.5 34.5C6.675 34.5 5.96875 34.2063 5.38125 33.6188C4.79375 33.0312 4.5 32.325 4.5 31.5V12C4.5 11.575 4.64375 11.2187 4.93125 10.9312C5.21875 10.6437 5.575 10.5 6 10.5C6.425 10.5 6.78125 10.6437 7.06875 10.9312C7.35625 11.2187 7.5 11.575 7.5 12V31.5H22.5C22.925 31.5 23.2812 31.6438 23.5688 31.9313C23.8563 32.2188 24 32.575 24 33C24 33.425 23.8563 33.7812 23.5688 34.0687C23.2812 34.3562 22.925 34.5 22.5 34.5H7.5Z"
        fill={fillColor}
      />
      <path
        d="M16.0312 24.3H24.4688C24.8672 24.3 25.2012 24.1634 25.4707 23.8903C25.7402 23.6172 25.875 23.2787 25.875 22.875C25.875 22.4712 25.7402 22.1328 25.4707 21.8597C25.2012 21.5866 24.8672 21.45 24.4688 21.45H16.0312C15.6328 21.45 15.2988 21.5866 15.0293 21.8597C14.7598 22.1328 14.625 22.4712 14.625 22.875C14.625 23.2787 14.7598 23.6172 15.0293 23.8903C15.2988 24.1634 15.6328 24.3 16.0312 24.3ZM16.0312 18.6H24.4688C24.8672 18.6 25.2012 18.4634 25.4707 18.1903C25.7402 17.9172 25.875 17.5787 25.875 17.175C25.875 16.7712 25.7402 16.4328 25.4707 16.1597C25.2012 15.8866 24.8672 15.75 24.4688 15.75H16.0312C15.6328 15.75 15.2988 15.8866 15.0293 16.1597C14.7598 16.4328 14.625 16.7712 14.625 17.175C14.625 17.5787 14.7598 17.9172 15.0293 18.1903C15.2988 18.4634 15.6328 18.6 16.0312 18.6ZM11.8125 30C11.0391 30 10.377 29.7209 9.82617 29.1628C9.27539 28.6047 9 27.9337 9 27.15V4.35C9 3.56625 9.27539 2.89531 9.82617 2.33719C10.377 1.77906 11.0391 1.5 11.8125 1.5H21.9023C22.2773 1.5 22.6348 1.57125 22.9746 1.71375C23.3145 1.85625 23.6133 2.05812 23.8711 2.31937L30.6914 9.23063C30.9492 9.49188 31.1484 9.79469 31.2891 10.1391C31.4297 10.4834 31.5 10.8456 31.5 11.2256V27.15C31.5 27.9337 31.2246 28.6047 30.6738 29.1628C30.123 29.7209 29.4609 30 28.6875 30H11.8125ZM21.6562 10.05V4.35H11.8125V27.15H28.6875V11.475H23.0625C22.6641 11.475 22.3301 11.3384 22.0605 11.0653C21.791 10.7922 21.6562 10.4537 21.6562 10.05Z"
        fill={fillColor}
      />
      <defs>
        <linearGradient
          id="paint0_linear_19755_48516"
          x1="24"
          y1="34.5"
          x2="0.508235"
          y2="15.4129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#370DB0" />
          <stop offset="1" stop-color="#9854EF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_19755_48516"
          x1="31.5"
          y1="30"
          x2="3.77816"
          y2="8.11433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#370DB0" />
          <stop offset="1" stop-color="#9854EF" />
        </linearGradient>
      </defs>
    </Svg>
  ) : (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7.5 34.5C6.675 34.5 5.96875 34.2063 5.38125 33.6188C4.79375 33.0312 4.5 32.325 4.5 31.5V12C4.5 11.575 4.64375 11.2187 4.93125 10.9312C5.21875 10.6437 5.575 10.5 6 10.5C6.425 10.5 6.78125 10.6437 7.06875 10.9312C7.35625 11.2187 7.5 11.575 7.5 12V31.5H22.5C22.925 31.5 23.2812 31.6438 23.5688 31.9313C23.8563 32.2188 24 32.575 24 33C24 33.425 23.8563 33.7812 23.5688 34.0687C23.2812 34.3562 22.925 34.5 22.5 34.5H7.5Z"
        fill={color}
      />
      <path
        d="M16.0312 24.3H24.4688C24.8672 24.3 25.2012 24.1634 25.4707 23.8903C25.7402 23.6172 25.875 23.2787 25.875 22.875C25.875 22.4712 25.7402 22.1328 25.4707 21.8597C25.2012 21.5866 24.8672 21.45 24.4688 21.45H16.0312C15.6328 21.45 15.2988 21.5866 15.0293 21.8597C14.7598 22.1328 14.625 22.4712 14.625 22.875C14.625 23.2787 14.7598 23.6172 15.0293 23.8903C15.2988 24.1634 15.6328 24.3 16.0312 24.3ZM16.0312 18.6H24.4688C24.8672 18.6 25.2012 18.4634 25.4707 18.1903C25.7402 17.9172 25.875 17.5787 25.875 17.175C25.875 16.7712 25.7402 16.4328 25.4707 16.1597C25.2012 15.8866 24.8672 15.75 24.4688 15.75H16.0312C15.6328 15.75 15.2988 15.8866 15.0293 16.1597C14.7598 16.4328 14.625 16.7712 14.625 17.175C14.625 17.5787 14.7598 17.9172 15.0293 18.1903C15.2988 18.4634 15.6328 18.6 16.0312 18.6ZM11.8125 30C11.0391 30 10.377 29.7209 9.82617 29.1628C9.27539 28.6047 9 27.9337 9 27.15V4.35C9 3.56625 9.27539 2.89531 9.82617 2.33719C10.377 1.77906 11.0391 1.5 11.8125 1.5H21.9023C22.2773 1.5 22.6348 1.57125 22.9746 1.71375C23.3145 1.85625 23.6133 2.05812 23.8711 2.31937L30.6914 9.23063C30.9492 9.49188 31.1484 9.79469 31.2891 10.1391C31.4297 10.4834 31.5 10.8456 31.5 11.2256V27.15C31.5 27.9337 31.2246 28.6047 30.6738 29.1628C30.123 29.7209 29.4609 30 28.6875 30H11.8125ZM21.6562 10.05V4.35H11.8125V27.15H28.6875V11.475H23.0625C22.6641 11.475 22.3301 11.3384 22.0605 11.0653C21.791 10.7922 21.6562 10.4537 21.6562 10.05Z"
        fill={color}
      />
    </Svg>
  );
