import { MouseEvent } from 'react';

import { Svg } from './styles';

interface SignOutIconProps {
  onClick?: (e: MouseEvent) => void;
  height?: string;
  width?: string;
  color?: string;
  isActive?: boolean;
  fillColor?: string;
}

export const SignOutIcon = ({
  onClick,
  height = '36',
  width = '36',
  color = '#AAAAAA',
  isActive,
  fillColor = 'url(#paint0_linear_19755_48548)',
}: SignOutIconProps) =>
  isActive ? (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M27.8999 4.5C28.7249 4.5 29.4312 4.79375 30.0187 5.38125C30.6062 5.96875 30.8999 6.675 30.8999 7.5L30.8999 28.5C30.8999 29.325 30.6062 30.0312 30.0187 30.6187C29.4312 31.2062 28.7249 31.5 27.8999 31.5L18.8999 31.5C18.4749 31.5 18.1187 31.3562 17.8312 31.0687C17.5437 30.7812 17.3999 30.425 17.3999 30C17.3999 29.575 17.5437 29.2187 17.8312 28.9312C18.1187 28.6437 18.4749 28.5 18.8999 28.5L27.8999 28.5L27.8999 7.5L18.8999 7.5C18.4749 7.5 18.1187 7.35625 17.8312 7.06875C17.5437 6.78125 17.3999 6.425 17.3999 6C17.3999 5.575 17.5437 5.21875 17.8312 4.93125C18.1187 4.64375 18.4749 4.5 18.8999 4.5L27.8999 4.5ZM9.6374 16.5L20.3999 16.5C20.8249 16.5 21.1812 16.6437 21.4687 16.9312C21.7562 17.2187 21.8999 17.575 21.8999 18C21.8999 18.425 21.7562 18.7812 21.4687 19.0687C21.1812 19.3562 20.8249 19.5 20.3999 19.5L9.6374 19.5L12.4499 22.3125C12.7249 22.5875 12.8624 22.925 12.8624 23.325C12.8624 23.725 12.7249 24.075 12.4499 24.375C12.1749 24.675 11.8249 24.8312 11.3999 24.8437C10.9749 24.8562 10.6124 24.7125 10.3124 24.4125L4.9499 19.05C4.6499 18.75 4.4999 18.4 4.4999 18C4.4999 17.6 4.6499 17.25 4.9499 16.95L10.3124 11.5875C10.6124 11.2875 10.9687 11.1437 11.3812 11.1562C11.7937 11.1687 12.1499 11.325 12.4499 11.625C12.7249 11.925 12.8562 12.2812 12.8437 12.6937C12.8312 13.1062 12.6874 13.45 12.4124 13.725L9.6374 16.5Z"
        fill={fillColor}
      />
      <defs>
        <linearGradient
          id="paint0_linear_19755_48548"
          x1="30.8999"
          y1="31.5"
          x2="3.90672"
          y2="5.10666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#AAAAAA" />
          <stop offset="1" stop-color="#222222" />
        </linearGradient>
      </defs>
    </Svg>
  ) : (
    <Svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8999 4.5C28.7249 4.5 29.4312 4.79375 30.0187 5.38125C30.6062 5.96875 30.8999 6.675 30.8999 7.5L30.8999 28.5C30.8999 29.325 30.6062 30.0312 30.0187 30.6187C29.4312 31.2062 28.7249 31.5 27.8999 31.5L18.8999 31.5C18.4749 31.5 18.1187 31.3562 17.8312 31.0687C17.5437 30.7812 17.3999 30.425 17.3999 30C17.3999 29.575 17.5437 29.2187 17.8312 28.9312C18.1187 28.6437 18.4749 28.5 18.8999 28.5L27.8999 28.5L27.8999 7.5L18.8999 7.5C18.4749 7.5 18.1187 7.35625 17.8312 7.06875C17.5437 6.78125 17.3999 6.425 17.3999 6C17.3999 5.575 17.5437 5.21875 17.8312 4.93125C18.1187 4.64375 18.4749 4.5 18.8999 4.5L27.8999 4.5ZM9.6374 16.5L20.3999 16.5C20.8249 16.5 21.1812 16.6437 21.4687 16.9312C21.7562 17.2187 21.8999 17.575 21.8999 18C21.8999 18.425 21.7562 18.7812 21.4687 19.0687C21.1812 19.3562 20.8249 19.5 20.3999 19.5L9.6374 19.5L12.4499 22.3125C12.7249 22.5875 12.8624 22.925 12.8624 23.325C12.8624 23.725 12.7249 24.075 12.4499 24.375C12.1749 24.675 11.8249 24.8312 11.3999 24.8437C10.9749 24.8562 10.6124 24.7125 10.3124 24.4125L4.9499 19.05C4.6499 18.75 4.4999 18.4 4.4999 18C4.4999 17.6 4.6499 17.25 4.9499 16.95L10.3124 11.5875C10.6124 11.2875 10.9687 11.1437 11.3812 11.1562C11.7937 11.1687 12.1499 11.325 12.4499 11.625C12.7249 11.925 12.8562 12.2812 12.8437 12.6937C12.8312 13.1062 12.6874 13.45 12.4124 13.725L9.6374 16.5Z"
        fill={color}
      />
    </Svg>
  );
